<template>
  <Box
    v-if="adGroupSelectedCreatives.length === 0"
    minHeight="132px"
    flex
    col
    spaceY="4"
    alignItems="center"
    justify="center"
  >
    <Paper
      color="secondary-light"
      rounded="xl"
      flex
      justify="center"
      alignItems="center"
      height="48px"
      width="48px"
    >
      <Icon icon="warning" size="lg" variant="outlined" color="secondary" />
    </Paper>
    <Typography variant="body2" alignText="center">
      No creatives were selected for the Ad Group. <br> Please add these in the Creatives tab.
    </Typography>
  </Box>

  <Box v-else flex col spaceY="10">
    <InputText
      v-model="searchQuery"
      placeholder="Search for a creative"
      icon="search"
    />
    <Table
      ref="tableRef"
      :selected="leverSelectedCreativesIds"
      :fields="tableColumns"
      :data="filteredCreatives"
      hoverable
      stickyFirstColumn
      checkbox
      @update:selected="onUpdateSelected"
    >
      <template #name="{ value, row: creative, hovered }">
        <TableCellName
          :hovered="hovered"
          :href="getCreativePreviewUrl(creative)"
          target="_blank"
          clickable
        >
          <template #chip>
            <Chip size="2xs" variant="outlined" :label="creative.id" />
          </template>
          {{ value }}
          <template #actions>
            <Tooltip v-if="getPreviewImageUrl(creative)" placement="top">
              <Link
                :to="getCreativePreviewUrl(creative)"
                target="_blank"
                class="flex"
              >
                <ButtonIcon icon="remove_red_eye" iconVariant="outlined" />
              </Link>
              <template #content>
                <img
                  :src="getPreviewImageUrl(creative)"
                  :width="getCreativePreviewSize(creative).width"
                  :alt="creative.name"
                >
              </template>
            </Tooltip>
          </template>
        </TableCellName>
      </template>

      <template #creativeStatus="{ rawValue }">
        <Chip
          size="2xs"
          variant="outlined"
          :color="creativeStatusChipMap[rawValue].color"
          :label="creativeStatusChipMap[rawValue].label"
        />
      </template>
    </Table>
  </Box>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import {
  Box,
  ButtonIcon,
  Chip,
  Icon,
  InputText,
  Link,
  Paper,
  Table,
  TableCellName,
  TableColumn,
  Tooltip,
  Typography,
} from '@lasso/luikit'

import { useCardLevers } from '../useCardLevers'
import { AdGroupCreative, creativeStatusChipMap, getBannerSize } from '../../../../shared/creatives'

const props = defineProps<{
  leverCreativesIds: number[]
  adGroupSelectedCreatives: AdGroupCreative[]
}>()

const emits = defineEmits<{
  'update:leverCreativesIds': [number[]]
}>()

const { getCreativePreviewUrl } = useCardLevers()!

const tableColumns: TableColumn<AdGroupCreative>[] = [
  {
    id: 'name',
    label: 'Creative',
    width: '433px',
  },
  {
    id: 'creativeStatus',
    label: 'Status',
    minWidth: '145px',
  },
]

const searchQuery = ref('')

const leverSelectedCreativesIds = computed(() => {
  return props.leverCreativesIds.map(String)
})

const filteredCreatives = computed(() => {
  if (!searchQuery.value.trim()) {
    return props.adGroupSelectedCreatives
  }
  return props.adGroupSelectedCreatives.filter((item) => {
    return item.name!.trim().toLowerCase().includes(searchQuery.value.trim().toLowerCase())
  })
})

const onUpdateSelected = (rowIds: string[]) => {
  emits('update:leverCreativesIds', rowIds.map(it => +it))
}

const getCreativePreviewSize = (row: AdGroupCreative) => {
  const [width = 0, height = 1] = getBannerSize(row).split('x').map(Number)

  const ratio = width / height

  if (ratio > 1) {
    return {
      width: 500,
      height: Math.round(500 / ratio),
    }
  }

  return {
    width: Math.round(500 * ratio),
    height: 500,
  }
}

const getPreviewImageUrl = ({ previewURL, bannerURL }: AdGroupCreative) => {
  return previewURL || bannerURL || ''
}
</script>
